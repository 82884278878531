import styles from "../autorization.module.css"
import { observer } from "mobx-react-lite"
import { useFormik } from "formik"
import { useApp } from "../../../hook/useApp"
import { classNames } from "primereact/utils"
import { useTranslation } from "react-i18next"
import { Captcha } from "primereact/captcha"

const Reset = observer(({ visible, handlerClose }) => {
  const { store } = useApp()
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: "",
      captcha: ""
    },
    validate: (data) => {
      let errors = {}

      if (!data.email) errors.email = t("reset.error-email")
      if (!data.captcha) errors.captcha = t("reset.error-captcha")

      return errors
    },
    onSubmit: async (data) => {
      await store.Auth.reset(data)
    }
  })

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name])

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }

  if (!visible) return ""

  return (
    <div className={styles["modal-window"]}>
      <div className={styles["modal-window__close"]} onClick={handlerClose} />
      <div className={styles["modal-window__title"]}>{t("reset.title")}</div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles["modal-window_row"]}>
          <input
            type="text"
            placeholder="E-mail"
            className={classNames({ "p-error": isFormFieldValid("email") })}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("email")}
        </div>
        <div
          className={`${styles["modal-window_row"]} ${styles["modal-window_row--center"]}`}
        >
          <Captcha
            siteKey={process.env.REACT_APP_CAPTCHA_KEY}
            onResponse={(response) => formik.setFieldValue("captcha", response)}
            onExpire={() => formik.setFieldValue("captcha", "")}
            language={localStorage.getItem("language")}
          />
          {getFormErrorMessage("captcha")}
        </div>
        <div
          className={`${styles["modal-window_row"]} ${styles["modal-window_row--center"]}`}
        >
          <div className={styles["modal-window_col"]}>
            <p className="p-error">{store.Auth?.authError || ""}</p>
            <button type="submit" className="app-button">
              {t("reset.submit")}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
})

export { Reset }
