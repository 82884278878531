import Axios from "axios"

const baseURL = process.env.REACT_APP_BACKEND_URL

export const axiosPublic = Axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": localStorage.getItem("language")
  }
})
