import styles from "../autorization.module.css"
import { useFormik } from "formik"
import { classNames } from "primereact/utils"
import { useApp } from "../../../hook/useApp"
import { observer } from "mobx-react-lite"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Captcha } from "primereact/captcha"
import { useEffect } from "react"

const SignUp = observer(({ visible, handlerClose }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { store } = useApp()

  const isSuccessfulRegistration = store.Auth.isSuccessfulRegistration

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      acceptOne: false,
      acceptTwo: false,
      captcha: ""
    },
    validate: (data) => {
      let errors = {}

      if (!data.name) {
        errors.name = t("sign-up.error-name")
      }

      if (data.name.length > 30) {
        errors.name = t("sign-up.error-name-length")
      }

      if (!data.email) {
        errors.email = t("sign-up.error-email")
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = t("sign-up.error-email-pattern")
      }

      if (data.password.length < 5) {
        errors.password = t("sign-up.error-password-min")
      }

      if (data.password.length > 30) {
        errors.password = t("sign-up.error-password-max")
      }

      if (!data.password) {
        errors.password = t("sign-up.error-password")
      }

      if (!data.confirmPassword) {
        errors.confirmPassword = t("sign-up.error-password-confirm")
      }

      if (data.password !== data.confirmPassword) {
        errors.confirmPassword = t("sign-up.error-password-match")
      }

      if (!data.acceptOne) {
        errors.acceptOne = t("sign-up.error-accept")
      }

      if (!data.captcha) {
        errors.captcha = t("sign-up.error-captcha")
      }

      return errors
    },
    onSubmit: async (data) => {
      store.Auth.setAuthError("")
      await store.Auth.registration(data)
      formik.setFieldValue("captcha", "")
    }
  })

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name])

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }

  const getFormInfoMessage = (name, message) => {
    return (
      !isFormFieldValid(name) && <small className="p-info">{message}</small>
    )
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isSuccessfulRegistration) {
        store.Auth.setSuccessfulRegistration(false)
        navigate("/", { replace: false })
      }
      clearTimeout(timer)
    }, 5000)
  }, [isSuccessfulRegistration])

  if (!visible) return ""

  if (isSuccessfulRegistration) {
    return (
      <div className={styles["modal-window"]}>
        <div className={styles["modal-window__close"]} onClick={handlerClose} />
        <div className={styles["modal-window__title"]}>
          {t("sign-up.information")}
        </div>
        <p>
          {t("sign-up.successful")}
          <br />
          {t("sign-up.successful-info")}
        </p>
      </div>
    )
  }

  return (
    <div className={styles["modal-window"]}>
      <div className={styles["modal-window__close"]} onClick={handlerClose} />
      <div className={styles["modal-window__title"]}>{t("sign-up.title")}</div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles["modal-window_row"]}>
          <input
            type="text"
            placeholder={t("sign-up.name")}
            maxLength="30"
            className={classNames({ "p-error": isFormFieldValid("name") })}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {getFormInfoMessage("name", t("sign-up.error-name-length"))}
          {getFormErrorMessage("name")}
        </div>
        <div className={styles["modal-window_row"]}>
          <input
            type="text"
            placeholder="E-mail"
            className={classNames({ "p-error": isFormFieldValid("email") })}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("email")}
        </div>
        <div className={styles["modal-window_row"]}>
          <input
            type="password"
            maxLength="30"
            placeholder={t("sign-up.password")}
            className={classNames({ "p-error": isFormFieldValid("password") })}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("password")}
        </div>
        <div className={styles["modal-window_row"] + " margin-bottom--25"}>
          <input
            type="password"
            maxLength="30"
            placeholder={t("sign-up.password-repeat")}
            className={classNames({
              "p-error": isFormFieldValid("confirmPassword")
            })}
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("confirmPassword")}
        </div>
        <div className={styles["modal-window_row"]}>
          <label
            className={classNames({
              "p-error": isFormFieldValid("acceptOne"),
              "checkbox-label": true
            })}
          >
            <input
              className="checkbox-label__control"
              type="checkbox"
              name="acceptOne"
              value={formik.values.acceptOne}
              onChange={formik.handleChange}
            />
            <span className="checkbox-label__inner">
              <span className="checkbox-label__inner-icon"></span>
              <span
                className={classNames({
                  "checkbox-label__inner-text": true,
                  "font-size--13": true,
                  "color-light-gray": !isFormFieldValid("acceptOne"),
                  "p-error": isFormFieldValid("acceptOne")
                })}
              >
                {t("sign-up.accept-title")}{" "}
                <Link to="/terms">{t("sign-up.terms")}</Link> {t("sign-up.and")}{" "}
                <Link to="/privacy">{t("sign-up.policy")}</Link>.
              </span>
            </span>
          </label>
        </div>
        <div className={styles["modal-window_row"]}>
          <label
            className={classNames({
              "p-error": isFormFieldValid("acceptTwo"),
              "checkbox-label": true
            })}
          >
            <input
              className="checkbox-label__control"
              type="checkbox"
              name="acceptTwo"
              value={formik.values.acceptTwo}
              onChange={formik.handleChange}
            />
            <span className="checkbox-label__inner">
              <span className="checkbox-label__inner-icon"></span>
              <span
                className={classNames({
                  "checkbox-label__inner-text": true,
                  "font-size--13": true,
                  "color-light-gray": !isFormFieldValid("acceptTwo"),
                  "p-error": isFormFieldValid("acceptTwo")
                })}
              >
                {t("sign-up.accept-promo")}
              </span>
            </span>
          </label>
        </div>
        <div
          className={`${styles["modal-window_row"]} ${styles["modal-window_row--center"]}`}
        >
          <Captcha
            siteKey={process.env.REACT_APP_CAPTCHA_KEY}
            onResponse={(response) => formik.setFieldValue("captcha", response)}
            onExpire={() => formik.setFieldValue("captcha", "")}
            language={localStorage.getItem("language")}
          />
          {getFormErrorMessage("captcha")}
        </div>
        <div
          className={`${styles["modal-window_row"]} ${styles["modal-window_row--center"]}`}
        >
          <div className={styles["modal-window_col"]}>
            <p className="p-error">{store.Auth?.authError || ""}</p>
            <button type="submit" className="app-button">
              {t("sign-up.submit")}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
})

export { SignUp }
