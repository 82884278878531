import { observer } from "mobx-react-lite"
import { useApp } from "../../../../hook/useApp"
import { useTranslation } from "react-i18next"

const AsideHeader = observer(() => {
  const { store } = useApp()
  const { t } = useTranslation()

  return (
    <div className="profile-aside__header">
      <div className="profile-aside__header-title">{t("account.profile")}</div>
      <p className="profile-aside__header-login">{store.Auth.authUser.sub}</p>
    </div>
  )
})

export default AsideHeader
